import React from "react";
import { useNavigate } from "react-router-dom";

const OurCounseling = [
  {
    title: "Career Counseling",
    icon: "/assets/lookingForIcon/Counseling.webp",
    path: "/career-counseling",
  },
  {
    title: "Parenting Tips",
    icon: "/assets/lookingForIcon/ParentingIcon.webp",
    path: "/parenting-tips",
  },
  {
    title: "Mental Health",
    icon: "/assets/lookingForIcon/MentalhealthIcon.webp",
    path: "/mental-health-counseling",
  },
  {
    title: "Happy Family",
    icon: "/assets/lookingForIcon/Happyfamilyicon.webp",
    path: "/happy-family-counseling",
  },
];

const MentalHealthCounseling = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className=" py-12 text-center mx-auto gap-2 flex flex-col items-center ">
        <h2 className="text-orange-500 text-4xl md:text-6xl font-bold ">
          Mental Health Counseling
        </h2>
        <div className="w-[50%] md:w-[25%] border-b-4 border-orange-500"></div>
      </div>
      <div className="container flex flex-col md:flex-row gap-4 justify-center items-center mx-auto pb-12 md:max-w-[1320px]">
        <div className="w-full md:w-1/2">
          <img
            src="/assets/mentalhealth.webp"
            alt="Self-Upliftment"
            className="w-full object-cover"
          />
        </div>
        <div className="w-full md:w-1/2">
          <p className="text-justify">
            Mental health counseling at Bibek Trust is dedicated to offering
            emotional support and practical tools for individuals experiencing
            mental health challenges such as anxiety, depression, stress, or
            trauma. Our experienced counselors create a safe and confidential
            space where clients can openly express their feelings and thoughts
            without judgment. We understand that mental well-being is critical,
            and we provide compassionate, personalized guidance to address each
            individual’s unique needs. By using evidence-based approaches and
            mindfulness techniques, we work collaboratively with clients to
            develop effective coping mechanisms, enhance resilience, and manage
            stress. Our goal is to empower individuals to take control of their
            mental health, building a foundation for long-term emotional
            well-being. Additionally, we offer practical strategies to help
            clients navigate daily challenges and encourage personal growth.
            Whether dealing with ongoing mental health concerns or seeking
            support to manage stress, our counseling services aim to provide the
            tools and guidance necessary for healing, self-awareness, and
            positive mental health transformation. We are here to support you on
            your journey to emotional recovery.
          </p>
        </div>
      </div>

      <div className="container py-12 text-center mx-auto gap-2 flex flex-col items-center ">
        <h2 className="text-orange-500 text-3xl md:text-5xl font-bold pb-4">
          Our Other Counselings
        </h2>
        <p className="text-center text-lg">
          Guiding Lives with Expert Counseling: Empowering Careers,
          Strengthening Families, Nurturing Mental Health, and Fostering
          Happiness for All.
        </p>
      </div>

      <div className="container flex flex-col justify-center mx-auto pb-12">
        <div className="grid grid-flow-row grid-cols-2 md:grid-cols-4 mx-auto gap-3">
          {OurCounseling.map((item, index) => {
            return (
              <div
                onClick={() => {
                  navigate(`${item.path}`); // Navigate to the homepage
                  window.scrollTo(0, 0); // Scroll to the top of the page
                }}
                className="p-6 shadow shadow-xl shadow-black/20 rounded-lg  flex flex-col justify-center items-center gap-4   md:w-[200px] hover:bg-orange-300 cursor-pointer"
                key={index}
              >
                <img src={item.icon} alt={item.title} width={100} />
                <p className="text-center text-blue-900 text-base font-semibold text-pretty">
                  {item.title}{" "}
                </p>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default MentalHealthCounseling;
