import React from "react";
import { useNavigate } from "react-router-dom";

const OurCounseling = [
  {
    title: "Career Counseling",
    icon: "/assets/lookingForIcon/Counseling.webp",
    path: "/career-counseling",
  },
  {
    title: "Parenting Tips",
    icon: "/assets/lookingForIcon/ParentingIcon.webp",
    path: "/parenting-tips",
  },
  {
    title: "Mental Health",
    icon: "/assets/lookingForIcon/MentalhealthIcon.webp",
    path: "/mental-health-counseling",
  },
  {
    title: "Happy Family",
    icon: "/assets/lookingForIcon/Happyfamilyicon.webp",
    path: "/happy-family-counseling",
  },
];

const CareerCounseling = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className=" py-12 text-center mx-auto gap-2 flex flex-col items-center ">
        <h2 className="text-orange-500 text-4xl md:text-6xl font-bold ">
          Career Counseling
        </h2>
        <div className="w-[50%] md:w-[25%] border-b-4 border-orange-500"></div>
      </div>
      <div className="container flex flex-col md:flex-row gap-4 justify-center items-center mx-auto pb-12 md:max-w-[1320px]">
        <div className="w-full md:w-1/2">
          <img
            src="/assets/career-counseling.webp"
            alt="Self-Upliftment"
            className="w-full object-cover"
          />
        </div>
        <div className="w-full md:w-1/2">
          <p className="text-justify">
            Career counseling at Bibek Trust is designed to help individuals
            make informed decisions about their professional journeys. Whether
            you’re unsure about your career direction, seeking a job change, or
            exploring further educational opportunities, our career counselors
            provide tailored guidance to help you achieve your goals. We start
            by helping you assess your skills, interests, and values to identify
            career options that best match your strengths and passions. Our
            counselors work with you to develop a clear understanding of your
            professional aspirations and create a strategic plan for reaching
            them. We provide expert advice on effective job search strategies,
            resume building, and interview preparation. Additionally, we offer
            guidance on career advancement, workplace challenges, and networking
            techniques, ensuring you’re well-prepared for success. Whether
            you’re entering the workforce for the first time or looking to
            advance in your career, our goal is to empower you to make confident
            choices that align with your professional vision. At Bibek Trust, we
            help you unlock your potential and achieve long-term career
            fulfillment.
          </p>
        </div>
      </div>

      <div className="container py-12 text-center mx-auto gap-2 flex flex-col items-center ">
        <h2 className="text-orange-500 text-3xl md:text-5xl font-bold pb-4">
          Our Other Counselings
        </h2>
        <p className="text-center text-lg">
          Guiding Lives with Expert Counseling: Empowering Careers,
          Strengthening Families, Nurturing Mental Health, and Fostering
          Happiness for All.
        </p>
      </div>

      <div className="container flex flex-col justify-center mx-auto pb-12">
        <div className="grid grid-flow-row grid-cols-2 md:grid-cols-4 mx-auto gap-3">
          {OurCounseling.map((item, index) => {
            return (
              <div
                onClick={() => {
                  navigate(`${item.path}`); // Navigate to the homepage
                  window.scrollTo(0, 0); // Scroll to the top of the page
                }}
                className="p-6 shadow shadow-xl shadow-black/20 rounded-lg  flex flex-col justify-center items-center gap-4   md:w-[200px] hover:bg-orange-300 cursor-pointer"
                key={index}
              >
                <img src={item.icon} alt={item.title} width={100} />
                <p className="text-center text-blue-900 text-base font-semibold text-pretty">
                  {item.title}{" "}
                </p>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default CareerCounseling;
