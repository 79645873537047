import React from "react";

const LanguageTutoring = () => {
  return (
    <>
      <div className=" py-12 text-center mx-auto gap-2 flex flex-col items-center ">
        <h2 className="text-orange-500 text-4xl md:text-6xl font-bold ">
          Language Tutoring
        </h2>
        <div className="w-[50%] md:w-[25%] border-b-4 border-orange-500"></div>
      </div>
      <div className="container flex flex-col md:flex-row gap-4 justify-center items-center mx-auto pb-12 md:max-w-[1320px]">
        <div className="w-full md:w-1/2">
        <img src="/assets/Language.webp" alt="Self-Upliftment" className="w-full object-cover" />
        </div>
        <div className="w-full md:w-1/2">
          <p className="text-justify">
            At Bibek Trust, we offer comprehensive language tutoring to help
            students improve their proficiency in English and other languages,
            such as Spanish and French. Our language programs are designed for
            all levels, from beginners to advanced learners, ensuring that every
            student receives tailored support based on their individual needs.
            Our approach focuses on developing both conversational skills and a
            strong understanding of grammar, vocabulary, and sentence structure.
            By using real-life scenarios, role-play exercises, and interactive
            methods, our tutors make language learning engaging, practical, and
            relevant to everyday situations. Our aim is not only to improve
            students’ speaking and listening skills but also to ensure they can
            confidently read, write, and communicate in their chosen language.
            Language proficiency is essential for academic success, especially
            for students looking to study abroad, pursue international
            internships, or engage with global networks. With Bibek Trust’s
            language tutoring, students can build fluency and confidence,
            preparing them for the challenges of studying and working in foreign
            environments. We also emphasize cultural awareness and language
            nuances, which are crucial for effective communication. Our language
            programs empower students to break language barriers, pursue their
            goals, and thrive in diverse settings.
          </p>
        </div>
      </div>
    </>
  );
};

export default LanguageTutoring;
