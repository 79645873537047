import React from "react";
import {
  FaFacebook,
  FaPhoneAlt,
  FaRegEnvelope,
  FaYoutube,
} from "react-icons/fa";
import { LuMapPin } from "react-icons/lu";
import { Link, useNavigate } from "react-router-dom";

const socials = [
  {
    path: "https://www.youtube.com/@bibektrust",
    icon: <FaYoutube />,
  },
  {
    path: "https://www.facebook.com/bibektrust/",
    icon: <FaFacebook />,
  },
];

const contact = [
  {
    name: "+91-9810077423",
    icon: <FaPhoneAlt />,
    path: "tel:9810077423",
  },
  {
    name: "+91-9810077426",
    icon: <FaPhoneAlt />,
    path: "tel:9810077426",
  },
  {
    name: "info@bibektrust.org",
    icon: <FaRegEnvelope />,
    path: "mailto:info@bibektrust.org",
  },
  {
    name: "J-4/1, Rajouri Garden, New Delhi - 110027",
    icon: <LuMapPin />,
    path: "",
  },
];

const Footer = () => {
  const date = new Date();
  const navigate = useNavigate();

  return (
    <div className="py-8 bg-blue-900 ">
      <div className="px-4 flex md:flex-row flex-col md:justify-between items-center gap-6">
        <div className="text-white flex flex-col items-center  gap-2 md:w-1/3 w-full order-2 md:order-none">
          {contact.map((item, index) => {
            return (
              <Link
                to={item.path}
                key={index}
                className="hover:text-orange-500 justify-start"
              >
                <div className="flex items-start gap-2">
                  <span className="text-lg">{item.icon}</span>
                  <p>{item.name}</p>
                </div>
              </Link>
            );
          })}
        </div>
        <div className="text-white text-center  md:w-1/3 w-full order-3 md:order-none flex flex-col justify-center items-center gap-4">
          <div
            onClick={() => {
              navigate("/");
              window.scrollTo(0, 0);
            }}
          >
            <img src="/assets/btsmall.png" alt="logo" width={80} height={80} />
          </div>
          <p>
            &copy; {date.getFullYear()} Bibek{" "}
            <span className="text-orange-500">Trust</span> | All rights
            reserved.
          </p>
        </div>

        {/* ------------------------ Socials ---------------------  */}
        <div className="text-white flex flex-col items-center gap-2 md:w-1/3 w-full order-1 md:order-none">
          <span>Follow us on </span>
          <div className="flex gap-4 text-xl">
            {socials.map((item, index) => {
              return (
                <Link
                  to={item.path}
                  target="_blank"
                  key={index}
                  className="hover:text-orange-500"
                >
                  {item.icon}
                </Link>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
