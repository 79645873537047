import React from "react";

const EmployeeAssistance = () => {
  return (
    <>
      <div className=" py-12 text-center mx-auto gap-2 flex flex-col items-center ">
        <h2 className="text-orange-500 text-4xl md:text-6xl font-bold ">
          Job Assistance
        </h2>
        <div className="w-[50%] md:w-[25%] border-b-4 border-orange-500"></div>
      </div>
      <div className="container flex flex-col md:flex-row gap-4 justify-center items-center mx-auto pb-12 md:max-w-[1320px]">
        <div className="w-full md:w-1/2">
        <img src="/assets/E-a.webp" alt="Self-Upliftment" className="w-full object-cover " />
        </div>
        <div className="w-full md:w-1/2">
          <p className="text-justify">
            Bibek Trust offers dedicated job assistance to help students
            transition smoothly into the professional world. We provide valuable
            support in key areas such as resume building, interview preparation,
            and networking. Our goal is to ensure that students are fully
            equipped to take the first step in their careers, whether it's
            securing an internship or finding an entry-level position. Our team
            connects students with potential employers, leveraging partnerships
            with organizations to provide job opportunities and internships. We
            also run workshops that focus on essential workplace skills,
            including effective communication, time management, professionalism,
            and teamwork. These workshops are designed to help students develop
            the competencies required for success in the workplace.
            Additionally, Bibek Trust’s job assistance includes
            personalized career advice, helping students identify their
            strengths and align them with job opportunities that match their
            skills and interests. Our focus is on empowering students to make
            confident career decisions, develop strong professional connections,
            and gain real-world experience. By providing continuous support
            throughout the job search process, we ensure that students feel
            prepared and ready to begin their careers on the right foot. Bibek
            Trust is committed to helping students build a successful career
            path and achieve long-term professional success.
          </p>
        </div>
      </div>
    </>
  );
};

export default EmployeeAssistance;
