import React from "react";

const SelfEmplyement = () => {
  return (
    <>
      <div className=" py-12 text-center mx-auto gap-2 flex flex-col items-center ">
        <h2 className="text-orange-500 text-4xl md:text-6xl font-bold ">
          Self Employement Projects
        </h2>
        <div className="w-[50%] md:w-[25%] border-b-4 border-orange-500"></div>
      </div>
      <div className="container flex flex-col md:flex-row gap-4 justify-center items-center mx-auto pb-12 md:max-w-[1320px]">
        <div className="w-full md:w-1/2">
          <img
            src="/assets/selfemployment.webp"
            alt="Self-Upliftment"
            className="w-full object-cover"
          />
        </div>
        <div className="w-full md:w-1/2">
          <p className="text-justify">
            Bibek Trust’s Self-Employment Projects are truly dedicated to
            inspire and empower individuals to achieve financial independence
            through support for small, sustainable ventures. We provide the
            resources, guidance, and training needed to help participants
            establish small businesses in fields such as handicrafts,
            soap-making, tailoring, and local food production. Recognizing that
            small-scale entrepreneurship can transform lives and uplift
            communities, Bibek Trust offers targeted workshops on essential
            skills like business planning, budgeting, and marketing, ensuring
            participants are prepared to successfully and strategically launch
            their ventures. Additionally, the Trust connects aspiring
            entrepreneurs with mentors who provide tailored advice on navigating
            business challenges, managing resources, and building a customer
            base. For those interested in specific trades, such as eco-friendly
            product creation, traditional crafts, or organic beauty products, we
            offer specialized vocational training to develop both technical
            skills and industry knowledge. To support these new ventures, Bibek
            Trust assists participants in accessing small grants and
            micro-loans, enabling them to secure initial capital for supplies
            and equipment. Through this comprehensive support, Bibek Trust
            empowers individuals to build self-reliant, sustainable livelihoods,
            fostering economic growth and resilience within their communities.
          </p>
        </div>
      </div>
    </>
  );
};

export default SelfEmplyement;
