import React from "react";
import {  useNavigate } from "react-router-dom";

const OurCounseling = [
  {
    title: "Career Counseling",
    icon: "/assets/lookingForIcon/Counseling.webp",
    path: "/career-counseling",
  },
  {
    title: "Parenting Tips",
    icon: "/assets/lookingForIcon/ParentingIcon.webp",
    path: "/parenting-tips",
  },
  {
    title: "Mental Health",
    icon: "/assets/lookingForIcon/MentalhealthIcon.webp",
    path: "/mental-health-counseling",
  },
  {
    title: "Happy Family",
    icon: "/assets/lookingForIcon/Happyfamilyicon.webp",
    path: "/happy-family-counseling",
  },
];

const HappyFamily = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className=" py-12 text-center mx-auto gap-2 flex flex-col items-center ">
        <h2 className="text-orange-500 text-4xl md:text-6xl font-bold ">
          Happy Family
        </h2>
        <div className="w-[50%] md:w-[25%] border-b-4 border-orange-500"></div>
      </div>
      <div className="container flex flex-col md:flex-row gap-4 justify-center items-center mx-auto pb-12 md:max-w-[1320px]">
        <div className="w-full md:w-1/2">
          <img
            src="/assets/happyfamily.webp"
            alt="Self-Upliftment"
            className="w-full object-cover"
          />
        </div>
        <div className="w-full md:w-1/2">
          <p className="text-justify">
            At Bibek Trust, we recognize the significance of healthy, harmonious
            family dynamics. Our Happy Family Counseling service is dedicated to
            improving communication, resolving conflicts, and enhancing
            relationships within families. Whether you’re facing parenting
            challenges, marital difficulties, or sibling conflicts, our
            counselors provide a compassionate and safe space to explore
            sensitive issues and work toward positive solutions. We aim to
            strengthen the bonds between family members by helping them better
            understand each other’s needs and perspectives. Our counselors equip
            families with practical strategies to improve communication, build
            trust, and foster a supportive environment. By addressing underlying
            issues and facilitating open conversations, we guide families toward
            mutual respect, empathy, and emotional well-being. Moreover, we
            focus on collaboration, ensuring that every family member is heard
            and valued throughout the process. Whether it’s navigating everyday
            family challenges or dealing with more complex concerns, our Happy
            Family Counseling service aims to help families build stronger, more
            resilient relationships, ultimately creating a more peaceful and
            loving home environment, thereby providing a sense of happiness.
          </p>
        </div>
      </div>

      <div className="container py-12 text-center mx-auto gap-2 flex flex-col items-center ">
        <h2 className="text-orange-500 text-3xl md:text-5xl font-bold pb-4">
          Our Other Counselings
        </h2>
        <p className="text-center text-lg">
          Guiding Lives with Expert Counseling: Empowering Careers,
          Strengthening Families, Nurturing Mental Health, and Fostering
          Happiness for All.
        </p>
      </div>

      <div className="container flex flex-col justify-center mx-auto pb-12">
        <div className="grid grid-flow-row grid-cols-2 md:grid-cols-4 mx-auto gap-3">
          {OurCounseling.map((item, index) => {
            return (
              <div
                onClick={() => {
                  navigate(`${item.path}`); // Navigate to the homepage
                  window.scrollTo(0, 0); // Scroll to the top of the page
                }}
                className="p-6 shadow shadow-xl shadow-black/20 rounded-lg  flex flex-col justify-center items-center gap-4   md:w-[200px] hover:bg-orange-300 cursor-pointer"
                key={index}
              >
                <img src={item.icon} alt={item.title} width={100} />
                <p className="text-center text-blue-900 text-base font-semibold text-pretty">
                  {item.title}{" "}
                </p>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default HappyFamily;
