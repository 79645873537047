import React from "react";
import { useNavigate } from "react-router-dom";

const OurCounseling = [
  {
    title: "Career Counseling",
    icon: "/assets/lookingForIcon/Counseling.webp",
    path: "/career-counseling",
  },
  {
    title: "Parenting Tips",
    icon: "/assets/lookingForIcon/ParentingIcon.webp",
    path: "/parenting-tips",
  },
  {
    title: "Mental Health",
    icon: "/assets/lookingForIcon/MentalhealthIcon.webp",
    path: "/mental-health-counseling",
  },
  {
    title: "Happy Family",
    icon: "/assets/lookingForIcon/Happyfamilyicon.webp",
    path: "/happy-family-counseling",
  },
];

const Counseling = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className=" py-12 text-center mx-auto gap-2 flex flex-col items-center ">
        <h2 className="text-orange-500 text-4xl md:text-6xl font-bold ">
          Counseling
        </h2>
        <div className="w-[50%] md:w-[25%] border-b-4 border-orange-500"></div>
      </div>
      <div className="container flex flex-col md:flex-row gap-4 justify-center items-center mx-auto pb-12 md:max-w-[1320px]">
        <div className="w-full md:w-1/2">
          <img
            src="/assets/counseling.webp"
            alt="Self-Upliftment"
            className="w-full object-cover"
          />
        </div>
        <div className="w-full md:w-1/2">
          <p className="text-justify">
            At Bibek Trust, we are dedicated to offering comprehensive
            counseling services designed to support individuals and families in
            navigating life's challenges and achieving their personal and
            professional goals. Counseling is an invaluable resource that
            provides a safe, confidential space for individuals to explore their
            thoughts and feelings, address personal difficulties, and gain
            clarity in their decision-making. Our team of professional and
            experienced counselors is committed to helping clients through a
            range of issues, from personal struggles to relationship conflicts,
            career decisions, and mental health concerns. We believe that
            everyone deserves access to compassionate, professional support, and
            our counseling services are tailored to meet each person’s unique
            needs. Whether you're facing stress, anxiety, family disputes, or
            other life transitions, our counselors are here to provide
            practical, evidence-based strategies for coping and growth. Through
            empathetic listening, thoughtful guidance, and proven therapeutic
            approaches, we empower individuals to find solutions, improve their
            well-being, and achieve greater emotional resilience. We help you
            navigate life’s challenges with confidence, hope, and support.
          </p>
        </div>
      </div>

      <div className="container py-12 text-center mx-auto gap-2 flex flex-col items-center ">
        <h2 className="text-orange-500 text-3xl md:text-5xl font-bold pb-4">
          Our Other Counselings
        </h2>
        <p className="text-center text-lg">
          Guiding Lives with Expert Counseling: Empowering Careers,
          Strengthening Families, Nurturing Mental Health, and Fostering
          Happiness for All.
        </p>
      </div>

      <div className="container flex flex-col justify-center mx-auto pb-12">
        <div className="grid grid-flow-row grid-cols-2 md:grid-cols-4 mx-auto gap-3">
          {OurCounseling.map((item, index) => {
            return (
              <div
              onClick={() => {
                navigate(`${item.path}`); // Navigate to the homepage
                window.scrollTo(0, 0); // Scroll to the top of the page
              }}
                className="p-6 shadow shadow-xl shadow-black/20 rounded-lg  flex flex-col justify-center items-center gap-4   md:w-[200px] hover:bg-orange-300 cursor-pointer"
                key={index}
              >
                <img src={item.icon} alt={item.title} width={100} />
                <p className="text-center text-blue-900 text-base font-semibold text-pretty">
                  {item.title}{" "}
                </p>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default Counseling;
