import React from "react";
import { Link, useNavigate } from "react-router-dom";

const About = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/"); // Navigate to the homepage
    window.scrollTo(0, 0); // Scroll to the top of the page
  };
  return (
    <>
      <div className=" py-12 text-center mx-auto gap-2 flex flex-col items-center ">
        <h2 className="text-orange-500 text-4xl md:text-6xl font-bold ">
          About Bibek Trust
        </h2>
        <div className="w-[50%] md:w-[25%] border-b-4 border-orange-500"></div>
      </div>

      <div className="container flex flex-col md:flex-row gap-4 justify-center items-center mx-auto pb-12 md:max-w-[1320px]">
        <div className="w-full md:w-1/3 md:flex justify-center md:h-[680px]">
          <img
            src="/assets/Aboutusbt.webp"
            alt="About Us"
            width={420}
            className="flex items-center justify-center object-cover rounded-md"
          />
        </div>
        <div className="w-full md:w-2/3 text-justify">
          <p>
            At Bibek Trust, we are committed to fostering holistic development
            through a unique blend of spiritual, academic, career, and mental
            well-being initiatives. Our vision is to empower individuals to
            thrive in all aspects of life, paving the way for a happier and more
            fulfilled existence.
          </p>
          <p>
            Founded on the principles of growth and support, Bibek Trust caters
            to individuals seeking to enhance their careers, improve essential
            skills, and prioritize wellness. We understand that personal and
            professional success is interconnected, and we strive to create an
            environment where each individual can unlock their full potential.
          </p>
          <h2 className="text-orange-500 text-2xl font-bold">Our Services</h2>
          <div className="pb-2">
            <ul>
              <li>
                <b className="text-blue-900">Counseling:</b> At Bibek Trust, our
                counseling services support individuals and families in
                navigating life's challenges with confidence and clarity. We
                specialize in{" "}
                <Link
                  className="text-blue-900 font-semibold underline"
                  to="/career-counseling"
                >
                  Career Counseling
                </Link>
                ,{" "}
                <Link
                  className="text-blue-900 font-semibold underline"
                  to="/happy-family-counseling"
                >
                  Happy Family Counseling
                </Link>
                ,{" "}
                <Link
                  className="text-blue-900 font-semibold underline"
                  to="/mental-health-counseling"
                >
                  Mental Health Counseling
                </Link>
                , and{" "}
                <Link
                  to="/parenting-tips"
                  className="text-blue-900 font-semibold underline"
                >
                  Parenting Guidance
                </Link>
                , offering compassionate and tailored solutions for personal and
                professional growth.
              </li>
              <li>
                <b className="text-blue-900">Training Programs:</b> We provide a
                range of training programs to empower individuals with essential
                skills for today’s competitive landscape. From enhancing
                communication to technical mastery, our training equips
                participants with the tools to thrive in diverse career fields.
              </li>
              <li>
                <b className="text-blue-900">Language Tutoring:</b>{" "}
                Understanding the importance of language proficiency in personal
                and professional growth, we offer language tutoring tailored to
                improve communication skills, opening up new opportunities for
                learning and global engagement.
              </li>
              <li>
                <b className="text-blue-900">Employment Assistance:</b> At Bibek
                Trust, we go beyond skill-building. We connect individuals with
                potential employers, offering placement assistance to secure
                meaningful job opportunities aligned with their skills and
                career aspirations.
              </li>
              <li>
                <b className="text-blue-900">Self-Upliftment Initiatives:</b> We
                believe in holistic growth. Through various workshops and
                activities, we promote self-awareness, confidence, and personal
                development, empowering individuals to reach their full
                potential in every aspect of life.
              </li>
              <li>
                <b className="text-blue-900">Self-Employment Projects:</b> Bibek
                Trust’s Self-Employment Projects empower individuals to achieve
                financial independence through support for small, sustainable
                ventures. We provide training in business skills, connect
                participants with mentors, and offer access to small grants to
                launch their businesses successfully.
              </li>
            </ul>
          </div>
          <p>
            Join us at{" "}
            <span
              onClick={handleClick}
              className="underline text-blue-900 cursor-pointer"
            >
              Bibek Trust
            </span>{" "}
            as we embark on a journey of self-discovery, growth, and
            empowerment. Together, we can build a community where every
            individual is equipped to succeed and flourish in their personal and
            professional lives. Your vision of a brighter future starts here!
          </p>
        </div>
      </div>
    </>
  );
};

export default About;
