import './App.css';
import { Routes, Route } from "react-router-dom";

// ------------- components ---------------------
import Home from './pages/home/Home';
import CareerChart from './pages/careerChart/CareerChart';
import Projects from './pages/projects/Projects';
import About from './pages/about/About';
import Header from './components/Header';
import Footer from './components/Footer';
import Training from './pages/taining/Training';
import LanguageTutoring from './pages/languageTutoring/LanguageTutoring';
import EmployeeAssistance from './pages/employeeAssistance/EmployeeAssistance';
import SelfUplifting from './pages/selfuplifting/SelfUplifting';
import SelfEmplyement from './pages/selfEmployementprojects/SelfEmplyement';
import Counseling from './pages/counseling/Counseling';
import CareerCounseling from './pages/careerCounseling/CareerCounseling';
import HappyFamily from './pages/happyFamily/HappyFamily';
import MentalHealthCounseling from './pages/mentalHealthCounseling/MentalHealthCounseling';
import ParentingTips from './pages/parentingTips/ParentingTips';
import PageNotFound from './components/PageNotFound';

function App() {
  return (
    <div>
      <Header />
      <Routes>
        <Route index element={<Home />} />
        <Route path='/' element={<Home />} />
        <Route path="about" element={<About />} />
        <Route path="careerchart" element={<CareerChart />} />
        <Route path="projects" element={<Projects />} />
        <Route path="counseling" element={<Counseling />} />
        <Route path="career-counseling" element={<CareerCounseling />} />
        <Route path="happy-family-counseling" element={<HappyFamily />} />
        <Route path="mental-health-counseling" element={<MentalHealthCounseling />} />
        <Route path="parenting-tips" element={<ParentingTips />} />
        <Route path="training" element={<Training />} />
        <Route path="language-tutoring" element={<LanguageTutoring />} />
        <Route path="employee-assistance" element={<EmployeeAssistance />} />
        <Route path="self-upliftment" element={<SelfUplifting />} />
        <Route path="self-employement-projects" element={<SelfEmplyement />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
