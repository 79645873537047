import React from "react";
import { useNavigate } from "react-router-dom";

const OurCounseling = [
  {
    title: "Career Counseling",
    icon: "/assets/lookingForIcon/Counseling.webp",
    path: "/career-counseling",
  },
  {
    title: "Parenting Tips",
    icon: "/assets/lookingForIcon/ParentingIcon.webp",
    path: "/parenting-tips",
  },
  {
    title: "Mental Health",
    icon: "/assets/lookingForIcon/MentalhealthIcon.webp",
    path: "/mental-health-counseling",
  },
  {
    title: "Happy Family",
    icon: "/assets/lookingForIcon/Happyfamilyicon.webp",
    path: "/happy-family-counseling",
  },
];

const ParentingTips = () => {
  const navigate = useNavigate();


  return (
    <>
      <div className=" py-12 text-center mx-auto gap-2 flex flex-col items-center ">
        <h2 className="text-orange-500 text-4xl md:text-6xl font-bold ">
          Parenting Tips
        </h2>
        <div className="w-[50%] md:w-[25%] border-b-4 border-orange-500"></div>
      </div>
      <div className="container flex flex-col md:flex-row gap-4 justify-center items-center mx-auto pb-12 md:max-w-[1320px]">
        <div className="w-full md:w-1/2">
          <img
            src="/assets/parenting.webp"
            alt="Self-Upliftment"
            className="w-full object-cover"
          />
        </div>
        <div className="w-full md:w-1/2">
          <p className="text-justify">
            Parenting is a rewarding but often challenging experience, and at
            Bibek Trust, we offer Parenting Tips to guide parents through the
            various stages of child-rearing. Whether you're a new parent or
            navigating the complexities of raising teenagers, our counselors
            provide practical advice, support, and emotional guidance tailored
            to your family's unique needs. We address a wide range of topics,
            including managing behavioral issues, fostering open communication,
            creating healthy boundaries, and supporting emotional and cognitive
            development. Also, our counselors work closely with parents to help
            them understand their child's emotional needs and provide tools for
            managing conflicts effectively. Along with this, we also offer
            strategies to build emotional resilience, nurture self-esteem, and
            promote positive relationships within the family. The goal of our
            Parenting Tips Counseling is to empower parents with the skills and
            knowledge needed to create a positive, nurturing environment for
            their children. By equipping parents with these essential tools, we
            help them raise confident and well-adjusted individuals who are
            prepared to succeed in life.
          </p>
        </div>
      </div>

      <div className="container py-12 text-center mx-auto gap-2 flex flex-col items-center ">
        <h2 className="text-orange-500 text-3xl md:text-5xl font-bold pb-4">
          Our Other Counselings
        </h2>
        <p className="text-center text-lg">
          Guiding Lives with Expert Counseling: Empowering Careers,
          Strengthening Families, Nurturing Mental Health, and Fostering
          Happiness for All.
        </p>
      </div>

      <div className="container flex flex-col justify-center mx-auto pb-12">
        <div className="grid grid-flow-row grid-cols-2 md:grid-cols-4 mx-auto gap-3">
          {OurCounseling.map((item, index) => {
            return (
              <div
                onClick={() => {
                  navigate(`${item.path}`); // Navigate to the homepage
                  window.scrollTo(0, 0); // Scroll to the top of the page
                }}
                className="p-6 shadow shadow-xl shadow-black/20 rounded-lg  flex flex-col justify-center items-center gap-4   md:w-[200px] hover:bg-orange-300 cursor-pointer"
                key={index}
              >
                <img src={item.icon} alt={item.title} width={100} />
                <p className="text-center text-blue-900 text-base font-semibold text-pretty">
                  {item.title}{" "}
                </p>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default ParentingTips;
