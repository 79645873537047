import React from "react";

const CareerChart = () => {
  return (
    <div>
      <div className="py-6 md:py-12 text-center mx-auto gap-2 flex flex-col items-center">
        <h2 className="text-orange-500 text-4xl md:text-6xl font-bold ">
          Career Chart
        </h2>
        <div className="w-[50%] md:w-[25%] border-b-4 border-orange-500"></div>
      </div>

      <div className="py-6 md:p-4  md:pb-12 container mx-auto">
        <img src="/assets/career.webp" alt="career chart" />
      </div>
    </div>
  );
};

export default CareerChart;
