import React from "react";
import Nav from "./Nav";
import { Link } from "react-router-dom";

const Header = () => {
  return (
    <div className="  bg-blue-900 ">
      <div className="container mx-auto px-4 flex justify-between items-center">
        <div className="text-white w-[200px] md:w-[240px]">
          <Link to="/">
            <img src="/assets/btlogo.png" alt="logo"  className="w-full" />
            {/* <h1 className="text-4xl font-bold">
              Bibek<span className="text-orange-500">Trust</span>.
            </h1> */}
          </Link>
        </div>

        {/* -------- Responsive nav ----------  */}
        <div className="py-8">
          <Nav />
        </div>
      </div>
    </div>
  );
};

export default Header;
