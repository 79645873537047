import React from "react";

const projectsData = [
  {
    location: "Gurdwara Anand Vihar Gurmat Camp",
    image: "/assets/projects/1.jpg",
  },
  {
    location: "Jobs Training Camp",
    image: "/assets/projects/20.jpg",
  },
  {
    location: "Interacting with Young minds",
    image: "/assets/projects/2.jpg",
  },
  {
    location: "Jobs Training Camp",
    image: "/assets/projects/21.jpg",
  },
  {
    location: "Camp at Gurdwara Anand Vihar",
    image: "/assets/projects/3.jpg",
  },
  {
    location: "Jobs & Career Training Camp",
    image: "/assets/projects/22.jpg",
  },
  {
    location: "Empowering future scholars",
    image: "/assets/projects/4.jpg",
  },
  {
    location: "Minority Scholarship Training",
    image: "/assets/projects/23.jpg",
  },
  {
    location: "Punjabi Learning Student awarded",
    image: "/assets/projects/5.jpg",
  },
  {
    location: "Upliftment Sikh Community Meeting, Delhi",
    image: "/assets/projects/6.jpg",
  },
  {
    location: "Gurudwara Krishna Park Tilak Nagar",
    image: "/assets/projects/7.jpg",
  },
  {
    location: "Skill Development Training, Rohtak",
    image: "/assets/projects/8.jpg",
  },
  {
    location: "Teacher Training Camp",
    image: "/assets/projects/24.jpg",
  },
  {
    location: "Preparing Future Scholars",
    image: "/assets/projects/9.jpg",
  },
  {
    location: "Gurmat camp at rohini sector-6",
    image: "/assets/projects/10.jpg",
  },
  {
    location: "Interacting with Kids For Bright Future",
    image: "/assets/projects/11.jpg",
  },
  {
    location: "GURMAT CAMP AT FARMER SOCIETY",
    image: "/assets/projects/12.jpg",
  },
  {
    location: "GURMAT CAMP AT GURDWARA RAMGARIYA, TRI NAGAR",
    image: "/assets/projects/13.jpg",
  },
  {
    location: "Language Training, Rohini",
    image: "/assets/projects/14.jpg",
  },
  {
    location: "GURMAT CAMP AT MUKHERJI NAGAR",
    image: "/assets/projects/15.jpg",
  },
  {
    location: "Skill Development Training",
    image: "/assets/projects/16.jpg",
  },
  {
    location: "Language training at Rohini",
    image: "/assets/projects/17.jpg",
  },
  {
    location: "Greater Noida Camp",
    image: "/assets/projects/18.jpg",
  },
  {
    location: "Greater Noida Camp",
    image: "/assets/projects/19.jpg",
  },
];

const Projects = () => {
  return (
    <>
      <div className=" py-12 text-center mx-auto gap-2 flex flex-col items-center ">
        <h2 className="text-orange-500 text-4xl md:text-6xl font-bold ">
          Projects
        </h2>
        <div className="w-[50%] md:w-[25%] border-b-4 border-orange-500"></div>
      </div>

      <div className="grid grid-flow-row grid-cols-2 md:grid-cols-5 mx-auto gap-3 pb-12 container">
        {projectsData.map((item, index) => {
          return (
            <div key={index}>
              <img src={item.image} alt={item.location} />
              <p className="capitalize">{item.location}</p>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default Projects;
