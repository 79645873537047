import { useLocation } from "react-router-dom";
import React, { useState } from "react";
import { CiMenuFries } from "react-icons/ci";
import { Link } from "react-router-dom";
import { IoClose } from "react-icons/io5";
import {
  FaFacebook,
  FaPhoneAlt,
  FaRegEnvelope,
  FaYoutube,
} from "react-icons/fa";

const menuItem = [
  { name: "Home", path: "/" },
  { name: "About Us", path: "/about" },
  { name: "Career Charts", path: "/careerchart" },
  { name: "Projects", path: "/projects" },
];

const Nav = () => {
  const [isOpen, setIsOpen] = useState(false);
  const pathname = useLocation();
  // console.log(pathname.pathname);

  const handletoggle = () => {
    setIsOpen(!isOpen);
  };

  // console.log(isOpen);

  return (
    <nav className="">
      {/* -------- desktop nav ----------  */}

      <div className="gap-8 text-lg text-white hidden md:flex">
        {menuItem.map((item, index) => {
          return (
            <Link
              to={item.path}
              key={index}
              className={`${
                pathname.pathname === item.path &&
                "text-orange-500 border-b-2 border-orange-500 transition duration-100 ease-in-out font-semibold"
              } hover:text-orange-500`}
            >
              {item.name}
            </Link>
          );
        })}
      </div>

      {/* -------- mobile nav ----------  */}
      <div className="md:hidden relative">
        {/* Hamburger Icon */}
        <button
          onClick={handletoggle}
          className="md:hidden text-white text-2xl cursor-pointer"
        >
    
          {isOpen ? "" : <CiMenuFries />}
        </button>
        <div
          className={`fixed w-[80%] bg-blue-950 h-screen  z-10 inset-0 transition-all duration-700  ease-in-out ${
            isOpen ? "block" : "hidden"
          } `}
          onClick={handletoggle}
        >
          <div className="flex items-center justify-between py-8 px-3 border-b  border-orange-500">
            <div>
              <img src="/assets/BTLogoGrey.png" alt="logo" className="w-3/4" />
            </div>
            <div className="text-3xl text-orange-500/90">
              <IoClose />
            </div>
          </div>
          <div className="flex flex-col justify-between h-[82vh] px-4 text-white">
            <div className="flex flex-col text-2xl gap-4  py-4  text-white/80">
              {menuItem.map((item, index) => {
                return (
                  <Link
                    to={item.path}
                    key={index}
                    onClick={handletoggle}
                    className={`${
                      pathname.pathname === item.path &&
                      "text-orange-500  border-b  border-orange-500/70 transition duration-100 ease-in-out font-semibold"
                    } hover:text-orange-500 border-b`}
                  >
                    {item.name}
                  </Link>
                );
              })}
            </div>

            <div className="flex flex-col gap-3 text-lg text-white">
              {/* ------------- contact details --------------   */}
              <Link to="tel:9810077423" className="flex items-center gap-2">
                <FaPhoneAlt /> +91-9810077423
              </Link>
              <Link to="tel:9810077426" className="flex items-center gap-2">
                <FaPhoneAlt /> +91-9810077426
              </Link>
              <Link
                to="mailto:info@bibektrust.org"
                className="flex items-center gap-2"
              >
                <FaRegEnvelope /> info@bibektrust.org
              </Link>

              <div className="flex items-center border-t pt-2 border-orange-500 gap-3">
                <span>Follow Us - </span>
                <Link
                  to="https://www.youtube.com/@bibektrust"
                  target="_blank"
                  className="text-2xl"
                >
                  <FaYoutube />
                </Link>
                <Link
                  to="https://www.facebook.com/bibektrust/"
                  target="_blank"
                  className="text-2xl"
                >
                  <FaFacebook />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Nav;
