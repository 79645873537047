import React from "react";

const Training = () => {
  return (
    <>
      <div className=" py-12 text-center mx-auto gap-2 flex flex-col items-center ">
        <h2 className="text-orange-500 text-4xl md:text-6xl font-bold ">
          Training
        </h2>
        <div className="w-[50%] md:w-[25%] border-b-4 border-orange-500"></div>
      </div>
      <div className="container flex flex-col md:flex-row gap-4 justify-center items-center mx-auto pb-12 md:max-w-[1320px]">
        <div className="w-full md:w-1/2">
        <img src="/assets/training.webp" alt="Self-Upliftment" className="w-full object-cover" />
        </div>
        <div className="w-full md:w-1/2">
          <p className="text-justify">
            Bibek Trust offers expert-led training programs to help students
            succeed in important academic exams and entrance tests. We provide
            comprehensive preparation for exams like IELTS, TOEFL, CUET, CLAT,
            GRE, SAT, GMAT, PTE, IPMAT, D.EI.ED, OET, and more. Our highly
            qualified instructors develop personalized study plans that cater to
            the specific needs and goals of each student. By using focused
            exercises, practice tests, and exam-specific strategies, we aim to
            enhance students' performance and boost their confidence in tackling
            these tests. The classes are interactive and dynamic, ensuring that
            students not only understand the core concepts but also build
            critical skills such as time management, effective study techniques,
            and problem-solving under pressure. Our training programs are
            designed to maximize students' potential and ensure they are fully
            prepared for the challenges of competitive exams. Additionally,
            Bibek Trust provides a supportive learning environment where
            students feel motivated and empowered to succeed. We are committed
            to helping students achieve their academic goals and perform to the
            best of their abilities in their entrance tests and exams. With our
            expert guidance, students can gain the skills and knowledge they
            need to excel and pursue their educational and professional dreams.
          </p>
        </div>
      </div>
    </>
  );
};

export default Training;
