import React from "react";
import { Link } from "react-router-dom";

const visionData = [
  {
    title: "Academic Excellence & Professional Growth",
    subtitle:
      '"Empowering Minds, Shaping Futures: Excellence and Growth Together."',
    description:
      "Academic excellence and professional growth both stem from a commitment to growth, powered by disciplined hard work, determination, and a drive for innovation. By fostering a mindset that values continuous learning, adaptability, and creativity, individuals can make meaningful contributions to their fields, aspiring to achieve global recognition, such as the Nobel Prize. This blend of knowledge-seeking and the courage to bring new ideas into reality creates lasting impacts and sets the stage for reaching one’s fullest potential in their career.",
  },

  {
    title: "Eternal Peace",
    subtitle: '"Finding Lasting Peace Through Inner Harmony and Balance"',
    description:
      "Eternal peace is a journey of remembering the sacred presence within ourselves and in the world around us, where real happiness flows from. As our awareness deepens, a sense of peace fills our hearts, eases our fears, and frees us from ego which leads to love and respect for the divine. Subsequently, we find ourselves drawn to purposeful actions and gentle detachment from materialism. Finding a balance between these two indispensable facets allows us to rise above the temporary challenges of the world. In this state, we uncover a peace that is limitless and everlasting.",
  },
  {
    title: "Social Welfare",
    subtitle:
      '"Empowering Communities, Uplifting Lives: Together for Lasting Change."',
    description:
      "Social welfare, rooted in the principle of Dasvandh, emphasizes the practice of dedicating ten percent of one’s resources, whether money, skills, or knowledge—as an act of gratitude to society. This concept is grounded in the belief that no one is beyond or above another; we are all equals, sharing the responsibility to uplift one another. Humanity is seen as one family, with every individual as a descendant of the Divine. By contributing our resources selflessly, we foster unity and compassion, creating a world where all can thrive together.",
  },
];

const OurActivities = [
  {
    title: "Counseling",
    icon: "/assets/lookingForIcon/CounselingIcon.webp",
    path: "counseling",
  },
  {
    title: "Training",
    icon: "/assets/lookingForIcon/Training.webp",
    path: "training",
  },
  {
    title: "Language Tutoring",
    icon: "/assets/lookingForIcon/LanguageTutoring.webp",
    path: "language-tutoring",
  },
  {
    title: "Job Assistance",
    icon: "/assets/lookingForIcon/SDFKJ.webp",
    path: "employee-assistance",
  },
  {
    title: "Self Upliftment",
    icon: "/assets/lookingForIcon/selfupliftment.webp",
    path: "self-upliftment",
  },
  {
    title: "Self Employement Projects",
    icon: "/assets/lookingForIcon/Selfemp.webp",
    path: "self-employement-projects",
  },
];

const Home = () => {
  return (
    <>
      {/* -------------------------------- Welcome section with Form ------------------  */}
      <div className="flex flex-col md:flex-row gap-4 md:gap-0">
        <div
          className="md:w-[70%] flex flex-col items-center justify-center"
          style={{
            backgroundImage: "url(/assets/Bthomebanner.webp)",
            // height: "520px",
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            backgroundBlendMode: "overlay",
          }}
        >
          <div className=" py-8 text-center mx-auto gap-2 flex flex-col items-center ">
            <h2 className="text-orange-500 text-4xl md:text-6xl font-bold ">
              Bibek Trust
            </h2>
            <div className="w-[50%] md:w-[25%] border-b-4 border-orange-500"></div>
          </div>
          <div className="flex items-center pb-4 flex-col gap-4">
            <h1 className="text-4xl text-white/80 font-bold text-center">
              ਬਿਬੇਕ ਬੁਧਿ ਬੀਚਾਰਿ ਗੁਰਮੁਖਿ ॥
            </h1>
            <h5 className="text-2xl text-orange-500 font-semibold">
              ਬਿਬੇਕ ਮਾਰਗ
            </h5>
            <p className="text-xl  text-white/80 text-base">
              The path to wisdom
            </p>
            {/* <Link to="/about">
              <button className="outline bg-gray-600 rounded-full mx-auto px-6 py-3 text-3xl outline-gray-600 bg-transparent text-white hover:outline-orange-500 hover:bg-transparent hover:text-white-600 ">
                Learn More
              </button>
            </Link> */}
            {/* <Link to="/about">
              <button className="md:hidden outline bg-gray-600 rounded-full mx-auto px-6 py-3 text-3xl outline-orange-500 bg-transparent text-white hover:outline-gray-00 hover:bg-transparent hover:text-white-600 ">
                Enquire Now
              </button>
            </Link> */}
          </div>
        </div>
        <div id="enquiryForm" className="md:w-[30%]">
          <iframe
            title=""
            aria-label=""
            frameBorder="0"
            // 460px
            style={{ height: "320px", width: "100%", border: "none" }}
            className=" flex items-center justify-center"
            src="https://forms.zohopublic.com/globedwise/form/BibekTrustEnquiryForm/formperma/jpO8lKMBB0e2PbtNRlOyqrWUfrAMfl99adgyQlaPF1o"
          ></iframe>
        </div>
      </div>

      {/* -------------------------------- Our Vision  -------------------------  */}

      <div className="container mx-auto py-12">
        <div className="w-full gap-3">
          <h2 className="text-5xl font-extrabold text-orange-500 text-center pb-4">
            Our Vision
          </h2>
          <p className="text-base text-lg text-center py-4">
            Empowering three pillars of fulfillment: Academic Excellence &
            Professional Growth, Social Welfare and Eternal Peace.
          </p>
          <div className="mx-auto flex flex-col text-center md:flex-row items-center justify-center md:items-stretch gap-4">
            {visionData.map((item, index) => {
              return (
                <div
                  className="md:w-[320px] w-full rounded-lg shadow-lg bg-blue-900 px-4 py-6 flex flex-col gap-4 hover:shadow-lg hover:shadow-orange-200"
                  key={index}
                >
                  <h2 className="text-2xl font-bold text-center text-orange-400">
                    {item.title}
                  </h2>
                  <p className="text-center text-white text-pretty italic">
                    {item.subtitle}
                  </p>
                  <p className="text-justify text-white/60">
                    {item.description}{" "}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      {/* -------------------------------------- What you are looking for ---------------------------  */}

      <div className="container mx-auto flex flex-col justify-center gap-4 pb-10">
        <h2 className="text-5xl font-extrabold text-orange-500 text-center pb-4">
          Our Activities
        </h2>
        <p className="text-center text-lg">
          Transforming Futures Through Career Counseling, Skill Training,
          Language Tutoring, Job Support, and Self-Employment Initiatives for
          Lifelong Growth.
        </p>

        <div className="grid grid-flow-row grid-cols-2 sm:grid-cols-3 md:grid-cols-6  mx-auto gap-3">
          {OurActivities.map((item, index) => {
            return (
              <Link
                to={item.path}
                className="p-6 shadow shadow-xl shadow-black/20 rounded-lg  flex flex-col justify-center items-center gap-4   md:w-[200px] hover:bg-orange-300 cursor-pointer"
                key={index}
              >
                <img src={item.icon} alt={item.title} width={100} />
                <p className="text-center text-blue-900 text-base font-semibold text-pretty">
                  {item.title}{" "}
                </p>
              </Link>
            );
          })}
        </div>
      </div>

      {/* --------------------------------------End ---------------------------  */}
    </>
  );
};

export default Home;
