import React from "react";

const SelfUplifting = () => {
  return (
    <>
      <div className=" py-12 text-center mx-auto gap-2 flex flex-col items-center ">
        <h2 className="text-orange-500 text-4xl md:text-6xl font-bold ">
          Self Uplifitment
        </h2>
        <div className="w-[50%] md:w-[25%] border-b-4 border-orange-500"></div>
      </div>
      <div className="container flex flex-col md:flex-row gap-4 justify-center items-center mx-auto pb-12 md:max-w-[1320px]">
        <div className="w-full md:w-1/2">
          <img
            src="/assets/Self-Upliftment.webp"
            alt="Self-Upliftment"
            className="w-full object-cover"
          />
        </div>
        <div className="w-full md:w-1/2">
          <p className="text-justify">
            Bibek Trust’s Self-Upliftment Program is thoughtfully designed to
            foster motivation, moral integrity, and a growth-oriented mindset in
            students, while also nurturing inner peace and emotional well-being.
            Recognizing that true success arises from core personal values,
            resilience, and a balanced outlook, we focus on building emotional
            strength and ethical principles. This empowers students to face
            life’s challenges with confidence, integrity, and a profound sense
            of peace of mind. Our program includes interactive workshops on
            self-awareness, stress management, and goal-setting, equipping
            students with practical tools to maintain motivation and mental
            clarity. Motivational talks, group discussions, and personalized
            mentorship sessions encourage students to reflect on their values,
            share experiences, and learn from others, fostering a supportive and
            harmonious community. Additionally, we guide students in developing
            in a way that aligns with their personal growth and
            community-oriented values. By emphasizing moral grounding, emotional
            well-being, and peace of mind alongside academic support, Bibek
            Trust’s Self-Upliftment Program prepares students to lead balanced,
            fulfilling lives. This holistic approach equips students with the
            resilience needed to thrive both personally and professionally,
            while making meaningful contributions to society.
          </p>
        </div>
      </div>
    </>
  );
};

export default SelfUplifting;
